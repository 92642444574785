.App {
  text-align: center;
}

.control-buttons {
  position: relative;
  z-index: 1000;
}

.container {
  margin: 20px auto;
  position: relative;
  height: 300px;
  width: 600px;
  overflow: hidden;
}

a-scene .a-enter-vr {
  display: none !important;
}

/* Scanner Target Styles */
.scanner-ui {
  position: relative;
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */
  pointer-events: none;
}

.scanner-corner {
  position: absolute;
  border: 6px solid white;
  width: 30px; /* Size of the corners */
  height: 30px;
  box-sizing: border-box;
}

.top-left {
  top: 0;
  left: 0;
  border-top-color: white;
  border-left-color: white;
}

.top-right {
  top: 0;
  right: 0;
  border-top-color: white;
  border-right-color: white;
}

.bottom-left {
  bottom: 0;
  left: 0;
  border-bottom-color: white;
  border-left-color: white;
}

.bottom-right {
  bottom: 0;
  right: 0;
  border-bottom-color: white;
  border-right-color: white;
}

.scanner-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
  animation: move-line 2s ease-in-out infinite;
}

/* Keyframes for moving the line */
@keyframes scan {
  0% {
    top: 0;
  }
  50% {
    top: 98%;
  }
  100% {
    top: 0;
  }
}

/* Accenture Placeholder Animation */
.accenture-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;
  width: 100vw;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  animation: fadeOut 1.2s ease forwards;
}

.accenture-placeholder h1 {
  font-size: 4rem;
  color: #A100FF;
  font-weight: bold;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards, zoomInOut 3s ease-out infinite;
}

/* Animation for text appearance */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

/* Optional fade-out animation after AR is ready */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

/* Keyframes for sliding in and out */
@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

/* Define a class for the UI while it's visible */
.ar-view-ui-enter {
  animation: slideInUp 0.5s ease forwards;
}

/* Define a class for the UI while it's exiting */
.ar-view-ui-exit {
  animation: slideOutDown 0.5s ease forwards;
}